import styles from '../styles/contact_page.module.css';
import getInTouchBtn from "../assets/images/getInTouchBtn.svg";

const ContactPage = () => {
    return (
        <>
         <div className={styles.container}>
            <div className={styles.left_div}>
                <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo"/></div>
                <h1 className={styles.heading}>Connect with Our Google Cloud Experts</h1>
                <p className={styles.desc}>Explore how we can support your cloud needs. Our Google Cloud experts are here to provide the solutions and guidance you require.</p>
                {/* <button className={styles.cont_btn}>Get In Touch</button> */}
                <div className={styles.cont_btn}><img src={getInTouchBtn}/></div>
            </div>

            <div className={styles.right_div}>
                <img src="\images\contact_hero.png" className={styles.right_img} alt="Hero Image"/>
            </div>
        </div>
        <div >
            <div className={styles.headingWrapper}>
				<p className={styles.short_heading}>Corporate Office Contact</p>
				<div className={styles.line}></div>
			</div>
            <div className={styles.offAdd}>
                <div className={styles.OfficeAddImg}><img src='\images\contactOffice.png'/></div>

                <div className={styles.address}>
                <h1>Headquarters</h1>
                <div className={styles.pinAddress}>
                <img src='\images\pinImg.png'/>
                <p><strong>India</strong></p>
                </div>
                
                <p className={styles.oAdd}>1st Floor, Survey no. 43 to 48, Plot. 83, Kavuri Hills, Jubilee Hills, Hyderabad, Telangana 500033</p>
                <p><strong>hello@hypr4.co</strong></p>
                <div className={styles.pinAddress}>
                <img src='\images\call.png'/>
                <p><strong>99000 99900</strong></p>
                </div>
                
            </div>
            </div>
        </div>
        </>
    );
};

export default ContactPage;