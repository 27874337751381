import styles from "../styles/footer.module.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {

	const navigate = useNavigate();

	const aboutPage = () => {
		navigate('/about');
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.footer_main}>
					<a href="/">
						<img className={styles.footer_img}
							src="/images/footer.png"
							alt="Company Name"
						/>
					</a>
					<div className={styles.social_icons}>
						<a href="#">
							<img
								src="/images/linkedin.png"
								alt="LinkedIn"
							/>
						</a>
						<a href="#">
							<img
								src="/images/youtube_icon.png"
								alt="Youtube"
							/>
						</a>
						<a href="#">
							<img
								src="/images/instagram.png"
								alt="Instagram"
							/>
						</a>
						<a href="#">
							<img
								src="/images/twitter.png"
								alt="Twitter"
							/>
						</a>
					</div>
				</div>

				<div className={styles.footer_menu}>
					<ul className={styles.footer_list}>
						<li className={styles.footer_item} onClick={() => aboutPage()}>About Us</li>
						<li className={styles.footer_item}>Services</li>
						<li className={styles.footer_item}>Contact Us</li>
						<li className={styles.footer_item}>Careers</li>
						<li className={styles.footer_item}>Resources</li>
					</ul>
				</div>

				<div className={styles.footer_extnt}>
					<ul className={styles.extnt_list}>
						<li className={styles.footer_item}>Terms</li>
						<li className={styles.footer_item}>Privacy Policy</li>
					</ul>
				</div>

				<div className={styles.footer_loc}>
					<span className={styles.country}>
						<img src="/images/location_icon.png" />India
					</span>
					<p className={styles.address}>
						Survey no. 43 to 48, Plot no. 83, Kavuri Hills, Jubilee
						Hills, Hyderabad, Telangana - 500033
					</p>
				</div>
			</div>
            <div className={styles.copyright_name}><p>©2024, All Rights reserved HYPR4 Cloud Tech Pvt. Ltd</p></div>
		</>
	);
}
