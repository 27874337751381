import { useState } from "react";
import styles from "../styles/navbar.module.css";
import { useNavigate } from 'react-router-dom';
import navOpen from "../assets/images/navOpen.svg";
import navClose from "../assets/images/navClose.svg";
import scheduleCall from "../assets/images/scheduleCall.svg"

export default function Navbar() {
	const [open, setOpen] = useState(false);
    const [subServices,setSubServices]=useState(false);
    const [subResources,setSubResources]=useState(false);
	const navigate = useNavigate();

	const aboutPage = () => {
		navigate('/about');
		setOpen(!open)
	}

	const migrationPage = () => {
		navigate('/migration')
		setOpen(!open)
	}

	const contactPage = () => {
		navigate('/contact')
		setOpen(!open)
	}

	const careersPage = () => {
		navigate('/careers')
		setOpen(!open)
	}

	return (
		<>
			<header className={styles.nav_menu}>
				<div id={styles.brand_name}>
					<a href="/">
						<img
							src="/images/comp_name.png"
							alt="Company Name"
						/>
					</a>
				</div>

				<div
					className={styles.menu_btn}
					onClick={() => setOpen(!open)}
				>
					{(!open) ? <img src={navOpen} alt="Nav Logo"/> : <img src={navClose} className={styles.navCloseImg} alt="NavCloseLogo"/>}
					
				</div>
			</header>
			{open ? (
				<div className={styles.menu_box}>
					{/* <img src="/images/leaf_nav.png" className={styles.leaf_nav} alt="leaves"/> */}
					<div className={styles.mobileContact}>
						<p>Need Help?</p>
						<h3>Lets Talk</h3>
						<div>
						<form className={styles.form}>
							<div className={styles.input}>
							<label>Phone number
                            <input type="tel" placeholder=" +91 9700 5566 77"/>
                            </label>
							</div>
                           <div className={styles.input}>
						   <label>Full Name
								<input type="text" />
							</label>
						   </div>
						   {/* <button >Schedule a call</button> */}
						   <div className={styles.contbtn}><img src={scheduleCall}/></div>
                        </form>
						</div>
					</div>
					<nav className={styles.navroute}>
						<ul className={styles.menu_list}>
							<li className={styles.menu_item} style={{cursor:"pointer"}} onClick={() => aboutPage()}><h1>About</h1>
							</li>
							<>
                                    <li className={styles.sub_item}>
                                    About Us
                                    </li>
                                    <li className={styles.sub_item}>
                                    Why Hypr4
                                    </li>
									<li className={styles.sub_item}>
                                    The I4
                                    </li>
							</>
							<li className={styles.menu_item} onClick={() => migrationPage()} style={{cursor:"pointer"}}><h1>Cloud Services</h1></li>
                                <>
                                    <li className={styles.sub_item} > {/*onClick={() => setSubServices(!subServices)} */}
                                    Migration
                                    </li>
                                    <li className={styles.sub_item}>
                                    Management
                                    </li>
                                    <li className={styles.sub_item}>
                                    Optimization
                                    </li>
                                    <li className={styles.sub_item}>
                                    Big Data
                                    </li>
                                    <li className={styles.sub_item}>
                                    Data Science
                                    </li>
                                    </>
							<li className={styles.menu_item} style={{cursor:"pointer"}} onClick={() => contactPage()}><h1>Contact</h1></li>
							<>
                                    <li className={styles.sub_item} onClick={() => migrationPage()}>
                                    Patner With Us
                                    </li>
                                    <li className={styles.sub_item}>
                                    Talk To Us
                                    </li>
							</>
							<li className={styles.menu_item} style={{cursor:"pointer"}} onClick={() => careersPage()}><h1>Careers</h1></li>
							<>
                                    <li className={styles.sub_item} >
                                    Explore Opportunities
                                    </li>
                                    <li className={styles.sub_item}>
                                    Share your CV
                                    </li>
							</>
                            <li className={styles.menu_item} onClick={() => setSubResources(!subResources)} style={{cursor:"pointer"}}><h1>Resources</h1></li>
                                <>
                                    <li className={styles.sub_item}>
                                    Blogs
                                    </li>
                                    <li className={styles.sub_item}>
                                    Case Studies
                                    </li>
                                </>
						</ul>
					</nav>
					<div className={styles.social_icons}>
						<a href="#" className={styles.sicons}>
							<img
								src="/images/linkedin.png"
								alt="LinkedIn"
							/>
						</a>
						<a href="#" className={styles.sicons}>
							<img
								src="/images/youtube_icon.png"
								alt="Youtube"
							/>
						</a>
						<a href="#" className={styles.sicons}>
							<img
								src="/images/instagram.png"
								alt="Instagram"
							/>
						</a>
						<a href="#" className={styles.sicons}>
							<img
								src="/images/twitter.png"
								alt="Twitter"
							/>
						</a>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}