import styles from "../styles/careers.module.css";
import getInTouchBtn from "../assets/images/getInTouchBtn.svg";

const Careers = () => {
    return (
        <div className={styles.careers}>
         <div className={styles.container}>
            <div className={styles.left_div}>
                <div className={styles.m_logo}><img src="/images/m_logo.png" alt="Company logo"/></div>
                <h1 className={styles.heading}>We Hire The Good and Make Them The Best</h1>
                <p className={styles.desc}>Be a Part of a Visionary Team Driving Cloud Innovation.</p>
                {/* <button className={styles.cont_btn}>Get In Touch</button> */}
                <div className={styles.cont_btn}><img src={getInTouchBtn}/></div>
                
            </div>

            <div className={styles.right_div}>
                <img src="\images\careersHeroImg.png" className={styles.right_img} alt="Hero Image"/>
            </div>
        </div>

        <div className={styles.hiringSkills}>
            <div className={styles.hiringHead}>
            <p className={styles.short_heading}>We Are Hiring For</p>
				<div className={styles.line}></div>
			</div>
			<div className={styles.inshorts_left}>
				<h1 className={styles.inshorts_head}>Hit APPLY If  You Know Any Of  The Two Below</h1>
            </div>
            <div className={styles.gridItems}>
                <div className={styles.gridItem1}>
                <img src="\images\hiringGCS.png" />
                <h3>Google Cloud Services</h3>
                </div>
                <div className={styles.gridItem2}>
                <img src="\images\hiringGoLang.png" />
                <h3>Go</h3>
                </div>
                <div className={styles.gridItem3}>
                <img src="\images\hiringPython.png" />
                <h3>Python</h3>
                </div>
                <div className={styles.gridItem4}>
                <img src="\images\hiringReact.png" />
                <h3>React</h3>
                </div>
            </div>
        </div>

        <div className={styles.JoinUs}>
            <div className={styles.joinUsHead}>
                <h1>Why Join Us?</h1>
                <div className={styles.joinUsHeadp}><p>Our HR team will reach you out when there's any opening that matches your profile</p></div>
            </div>
            <div  className={styles.joinUsGrid}>
                <div className={styles.JoinUsLeft}>
                <div className={styles.joinus1}>
                    <img src="\images\joinus1.png" />
                    <h3>Expert Team</h3>
                    <p>Collaborate with certified Google Cloud professionals and industry leaders.</p>
                </div>
                <div className={styles.joinus2}>
                    <img src="\images\joinus2.png" />
                    <h3>Innovative Projects</h3>
                    <p>Engage in transformative projects with Fortune 500 companies and leading global brands.</p>
                </div>
                <div className={styles.joinus3}>
                    <img src="\images\joinus3.png" />
                    <h3>Next-gen Technology</h3>
                    <p>Work with the latest in Google Cloud, Big Data, AI, and cloud optimization technologies.</p>
                </div>
                </div>
                <div className={styles.JoinUsMiddle}>
                   <img src="\images\joinus.png"/>
                </div>
                <div className={styles.JoinUsRight}>
                <div className={styles.joinus4}>
                    <img src="\images\joinus4.png" />
                    <h3>Growth Opportunities</h3>
                    <p>Fast-track your career with clear growth paths, mentorship, and professional development.</p>
                </div>
                <div className={styles.joinus5}>
                    <img src="\images\joinus5.png" />
                    <h3>Dynamic Work Environment</h3>
                    <p>Thrive in a collaborative, supportive, and flexible workplace that values creativity.</p>
                </div>
                <div className={styles.joinus6}>
                    <img src="\images\joinus6.png" />
                    <h3>Competitive Benefits</h3>
                    <p>Enjoy attractive compensation, comprehensive health benefits, and performance-based incentives.</p>
                </div>
                </div>
            </div>
        </div>

        {/* <div>
            <div className={styles.joinUsHead}>
                <h1>Share Your Details</h1>
                <div className={styles.joinUsHeadp}><p>Our HR team will reach you out when there’s any opening that matches your profile</p></div>
            </div>
            <div className={styles.contactForm}>
                <form>
                    <div className={styles.contact1}>
                        <label>Full Name</label>
                        <input type="text" placeholder="Name"/>

                        <label>Email ID</label>
                        <input type="email" placeholder="abc@mail.com" />
                    </div>
                    <div className={styles.contact2}>
                        <label>Contact Number</label>
                        <input type="email" placeholder="+91 90000 90000" />

                        <label>LinkedIn URL</label>
                        <input type="email" placeholder="Paste the Link" />
                    </div>
                    <div>
                    <label>
                       <input type="radio" name="options"/>
                       <span>Google Cloud</span>
                    </label>
                    <label>
                       <input type="radio" name="options"/>
                       <span>GoLnag</span>
                    </label>
                    <label>
                       <input type="radio" name="options"/>
                       <span>Python</span>
                    </label>
                    <label>
                       <input type="radio" name="options"/>
                       <span>React</span>
                    </label>
                    </div>
                </form>
            </div>
        </div> */}
        </div>
    )
}

export default Careers;